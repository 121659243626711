/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

interface LoginProps {
  username: string;
  password: string;
  provider: CognitoHostedUIIdentityProvider;
}

interface LoginError extends Error {
  name: string;
  code: string;
}
type Session = LoginError | Error | unknown;
const check: (session: Session) => Promise<void> = (session: Session) => {
  console.info(JSON.stringify(session, null, 2)); // eslint-disable-line no-console

  if ((session as LoginError).name === 'PasswordResetRequiredException') {
    return Promise.reject({
      redirectTo: '/resetpassword',
      logoutUser: false,
      message: false,
    });
  }

  return Auth.currentSession() // eslint-disable-line @typescript-eslint/no-explicit-any
    .then<void>((user) => {
      if (session) {
        console.info(JSON.stringify(session, null, 2)); // eslint-disable-line no-console
      }

      if (!user) {
        return Promise.reject(new Error('intoto.auth.user_not_signed_in'));
      }

      return; // eslint-disable-line
    });
};

export const useAuthProvider = () => ({
  login: ({ username, password, provider }: LoginProps) =>
    username && password && !provider
      ? Auth.signIn(username, password)
          .then((session) => {
            console.info(JSON.stringify(session, null, 2)); // eslint-disable-line no-console

            if (session.challengeName === 'NEW_PASSWORD_REQUIRED') {
              console.info('Halleluja'); // eslint-disable-line no-console
              return Auth.completeNewPassword(session, password, {});
            }

            return session;
          })
          .catch((ex: Error) => {
            console.info(JSON.stringify(ex, null, 2)); // eslint-disable-line no-console

            return Promise.reject(ex);
          })
      : Auth.federatedSignIn({ provider }),
  logout: () => Auth.signOut(),
  checkAuth: check,
  checkError: check,
  getPermissions: () => {
    if (!Auth.Credentials) {
      return Promise.resolve({});
    }

    return Promise.all([Auth.currentSession(), Auth.currentCredentials()])
      .then(([session, { identityId }]) => ({
        claims: { ...session.getIdToken().payload, identityId },
      }))
      .catch(() => ({}));
  },
});
