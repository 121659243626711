import { FunctionComponent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextField } from '@mui/material';

export type InputProps = FieldRenderProps<string, HTMLElement>;

export const Input: FunctionComponent<InputProps> = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}: InputProps) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    fullWidth
  />
);

export default Input;
