import icon from '@mui/icons-material/EditNotifications';

import { List } from './List';
import { Edit } from './Edit';
import { Create } from './Create';
import { Show } from './Show';

export const Event = {
  name: 'event',
  list: List,
  edit: Edit,
  create: Create,
  show: Show,
  icon,
};
