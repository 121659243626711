import type { PropsWithChildren } from 'react';

import { FunctionComponent } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router';
import { Auth } from '@aws-amplify/auth';

import { Box, Button, CardActions, CircularProgress } from '@mui/material';
import { useNotify, useTranslate, useSafeSetState } from 'ra-core';
import { Login } from 'ra-ui-materialui';

import { Input } from '../Input';

const InputField = ({ children }: PropsWithChildren<unknown>) => (
  <Box sx={{ marginTop: '1em' }}>{children}</Box>
);

interface FormData {
  username?: string;
  code?: string;
  password?: string;
  passwordverified?: string;
}

type Props = Record<string, unknown>;

const ResetPasswordForm: FunctionComponent<Props> = (props: Props) => {
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();

  const validate = (values: FormData) => {
    const errors: FormData = {
      username: undefined,
      code: undefined,
      password: undefined,
      passwordverified: undefined,
    };

    const required = () => translate('ra.validation.required');

    if (!values.username) {
      errors.username = required();
    }

    if (!values.code) {
      errors.code = required();
    }

    if (!values.password) {
      errors.password = required();
    } else if (!values.passwordverified) {
      errors.passwordverified = required();
    } else if (values.password !== values.passwordverified) {
      errors.passwordverified = translate(
        'intoto.validation.password_different',
      );
    }

    return errors;
  };

  const submit = (values: FormData) => {
    setLoading(true);

    Auth.forgotPasswordSubmit(
      values.username || '',
      values.code || '',
      values.password || '',
    )
      .then((data: string) => {
        console.info(data); // eslint-disable-line no-console

        navigate('/login');

        return data;
      })
      .catch((error: Error) => {
        notify(
          typeof error === 'string'
            ? error
            : error?.message || 'ra.auth.sign_in_error',
          { type: 'warning' },
        );

        return undefined;
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <InputField>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </InputField>
            <InputField>
              <Field
                id="code"
                name="code"
                component={Input}
                label={translate('intoto.auth.code')}
                disabled={loading}
              />
            </InputField>
            <InputField>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </InputField>
            <InputField>
              <Field
                id="passwordverified"
                name="passwordverified"
                component={Input}
                label={translate('intoto.auth.password_verified')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </InputField>
          </Box>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && (
                <CircularProgress
                  sx={{ marginRight: 1 }}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('intoto.auth.reset_password')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

ResetPasswordForm.defaultProps = {};

export const ResetPassword: FunctionComponent<Props> = (props: Props) => (
  <Login {...props} /> // eslint-disable-line react/jsx-props-no-spreading
);

ResetPassword.defaultProps = {
  children: <ResetPasswordForm />,
};

export default ResetPassword;
