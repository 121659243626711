/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';

import {
  ArrayField,
  ArrayInput,
  Datagrid,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextInput,
  TextField,
} from 'react-admin';

import { timezones } from '../../config';

import { ArrayChipField } from '../../fields/ArrayChipField/ArrayChipField';
import { ArrayCountField } from '../../fields/ArrayCountField/ArrayCountField';

export const StationsFormList: FunctionComponent = (props) => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" sortable={false} />
    <ReferenceField reference="provider" source="provider">
      <TextField source="name" />
    </ReferenceField>
    <TextField source="name" sortable={false} />
    <TextField source="description" sortable={false} />
    <ArrayChipField source="coordinates" />
    <TextField source="timezone" />
    <ArrayCountField source="series" icon="series" />
  </Datagrid>
);

export const StationsFormShow: FunctionComponent = (props) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" />
    <ReferenceField reference="provider" source="provider">
      <TextField source="name" />
    </ReferenceField>
    <TextField source="name" />
    <TextField source="description" />
    <TextField source="coordinates" />
    <TextField source="timezone" />
    <ArrayField source="series">
      <Datagrid>
        <ReferenceField reference="series" source="id">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </ArrayField>
    <TextField source="" />
  </SimpleShowLayout>
);

export const StationsFormCreate: FunctionComponent = (props) => (
  <SimpleForm {...props}>
    <TextInput source="id" />

    <ReferenceInput reference="provider" source="provider">
      <SelectInput optionText="id" source="id" filter="" />
    </ReferenceInput>

    <TextInput source="name" />
    <TextInput source="description" />
    <ArrayInput source="areas">
      <SimpleFormIterator>
        <TextInput source="" />
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source="coordinates">
      <SimpleFormIterator>
        <NumberInput source="" />
      </SimpleFormIterator>
    </ArrayInput>
    <SelectInput source="timezone" choices={timezones} />
    <ArrayInput source="series">
      <SimpleFormIterator>
        <ReferenceInput reference="series" source="id">
          <SelectInput optionText="id" source="id" />
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);

export const StationsFormEdit = StationsFormCreate;
