/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';
import { AppBar } from 'react-admin';

import { Typography, Box } from '@mui/material';

import { Logo } from '../Logo';

export const MyAppBar: FunctionComponent = (props) => (
  <AppBar {...props}>
    <Typography
      variant="h6"
      color="inherit"
      sx={{
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      id="react-admin-title"
    />
    <Logo />
    <Box component="span" sx={{ flex: 1 }} />
  </AppBar>
);
