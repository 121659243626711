export const englishMessages = {
  intoto: {
    auth: {
      code: 'Validation code',
      password_verified: 'Password repeated',
      reset_password: 'Set new password',
    },
  },
};

export default englishMessages;
