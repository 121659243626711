import { FunctionComponent } from 'react';

import { Edit } from 'react-admin';
import { ProviderFormEdit } from './base';

export const ProviderEdit: FunctionComponent = () => (
  <Edit>
    <ProviderFormEdit />
  </Edit>
);
