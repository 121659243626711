import { FunctionComponent } from 'react';

import { Show } from 'react-admin';

import { AreasFormShow } from './base';

export const AreasShow: FunctionComponent = () => (
  <Show>
    <AreasFormShow />
  </Show>
);
