import { FunctionComponent } from 'react';

import { List } from 'react-admin';
import { SeriesFormList } from './base';

export const SeriesList: FunctionComponent = () => (
  <List>
    <SeriesFormList />
  </List>
);
