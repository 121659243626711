import { FunctionComponent } from 'react';

import { Create } from 'react-admin';
import { SeriesFormCreate } from './base';

export const SeriesCreate: FunctionComponent = () => (
  <Create>
    <SeriesFormCreate />
  </Create>
);
