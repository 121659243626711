/* eslint-disable react/jsx-props-no-spreading */

import {
  ArrayInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
} from 'react-admin';

import { ControlHolder } from './controlholder';

export const ConverterArray = () => (
  <ArrayInput source="converter">
    <SimpleFormIterator className="config-form">
      <FormDataConsumer>
        {({ getSource = (name) => name }) => (
          <ControlHolder label="Type">
            <SelectInput
              source={getSource('type')}
              label="Type"
              choices={[
                { id: 'plus', name: 'Plus' },
                { id: 'minus', name: 'Minus' },
                { id: 'range', name: 'Range' },
                { id: 'factor', name: 'Factor' },
                { id: 'calculate', name: 'Calculate' },
              ]}
            />
          </ControlHolder>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name, formData, scopedFormData }) =>
          scopedFormData &&
          scopedFormData.type !== 'range' && (
            <ControlHolder label="Period">
              <DateInput
                source={getSource('from')}
                label="From"
                defaultValue={formData.from}
              />
              <DateInput
                source={getSource('to')}
                label="To"
                defaultValue={formData.to}
              />
            </ControlHolder>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name, scopedFormData }) =>
          scopedFormData &&
          scopedFormData.type === 'range' && (
            <ControlHolder label="Range">
              <NumberInput source={getSource('from')} label="From" />
              <NumberInput source={getSource('to')} label="To" />
            </ControlHolder>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name }) => (
          <ControlHolder label="Parameter">
            <SelectInput
              source={getSource('parameter')}
              choices={[
                { id: 'asml', name: 'AMSL' },
                { id: 'milliamp', name: 'Milliamp' },
              ]}
            />
          </ControlHolder>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name, scopedFormData }) =>
          scopedFormData &&
          ['calculate', 'range'].includes(scopedFormData.type) && (
            <ControlHolder label="Value">
              <NumberInput source={getSource('value.factor')} label="Factor" />
              <NumberInput
                source={getSource('value.variable')}
                label="Variable"
              />
            </ControlHolder>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name, scopedFormData }) =>
          scopedFormData &&
          ['plus', 'minus', 'factor'].includes(scopedFormData.type) && (
            <ControlHolder label="Value">
              <NumberInput source={getSource('value')} label="Value" />
            </ControlHolder>
          )
        }
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

export default ConverterArray;
