import icon from '@mui/icons-material/PushPin';

import { StationsList } from './list';
import { StationsEdit } from './edit';
import { StationsCreate } from './create';
import { StationsShow } from './show';

export const Stations = {
  name: 'station',
  list: StationsList,
  edit: StationsEdit,
  create: StationsCreate,
  show: StationsShow,
  icon,
  options: { label: 'Stations' },
};
