/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';

import {
  Datagrid,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  TextField,
} from 'react-admin';
import { JsonField, JsonInput } from 'react-admin-json-view';

export const List: FunctionComponent = (props) => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" sortable={false} />
    <TextField source="name" sortable={false} />
  </Datagrid>
);

export const Show: FunctionComponent = (props) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <JsonField
      source="data"
      jsonString={false}
      reactJsonOptions={{
        name: null,
        collapsed: false,
        enableClipboard: true,
        displayDataTypes: false,
      }}
    />
  </SimpleShowLayout>
);

export const CreateEdit: FunctionComponent = (props) => (
  <SimpleForm {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
    <JsonInput
      source="data"
      jsonString={false}
      reactJsonOptions={{
        name: null,
        collapsed: false,
        enableClipboard: true,
        displayDataTypes: false,
      }}
    />
  </SimpleForm>
);
