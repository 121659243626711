import { FunctionComponent } from 'react';

import { Show } from 'react-admin';

import { Show as FormShow } from './base';

export const ConfigShow: FunctionComponent = () => (
  <Show>
    <FormShow />
  </Show>
);
