import { FunctionComponent } from 'react';

import { Edit } from 'react-admin';
import { AreasFormEdit } from './base';

export const AreasEdit: FunctionComponent = () => (
  <Edit>
    <AreasFormEdit />
  </Edit>
);
