/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';

import {
  Datagrid,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

export const ProviderFormList: FunctionComponent = (props) => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
  </Datagrid>
);

export const ProviderFormShow: FunctionComponent = (props) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
  </SimpleShowLayout>
);

const ProviderInputElements = () => (
  <>
    <TextInput source="name" />
    <TextInput source="description" multiline />
  </>
);

export const ProviderFormCreate: FunctionComponent = (props) => (
  <SimpleForm {...props}>
    <TextInput source="id" />
    <ProviderInputElements />
  </SimpleForm>
);

export const ProviderFormEdit: FunctionComponent = (props) => (
  <SimpleForm {...props}>
    <TextField source="id" />
    <ProviderInputElements />
  </SimpleForm>
);
