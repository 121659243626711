import { FunctionComponent } from 'react';

import { Edit as RAEdit } from 'react-admin';
import { EditForm } from './base';

export const Edit: FunctionComponent = () => (
  <RAEdit>
    <EditForm />
  </RAEdit>
);
