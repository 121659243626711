import type { PropsWithChildren } from 'react';

import { Box, Button, CardActions, CircularProgress } from '@mui/material';
import { useLogin, useNotify, useSafeSetState, useTranslate } from 'ra-core';
import type { LoginProps } from 'ra-ui-materialui';
import { Login } from 'ra-ui-materialui';
import { FunctionComponent } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router';
import { Input } from '../Input';

interface FormData {
  username: string;
  password: string;
}

interface Props {
  redirectTo?: string;
}

const InputField = ({ children }: PropsWithChildren<unknown>) => (
  <Box sx={{ marginTop: '1em' }}>{children}</Box>
);

export const LoginForm: FunctionComponent<Props> = (props: Props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();

  const validate = (values: FormData) => {
    const errors: Partial<FormData> = {
      username: undefined,
      password: undefined,
    };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = (values: FormData) => {
    setLoading(true);
    login(values, redirectTo)
      .catch((error) => {
        if (error.name === 'PasswordResetRequiredException') {
          navigate('/resetpassword');

          return undefined;
        }

        notify(
          typeof error === 'string'
            ? error
            : error?.message || 'ra.auth.sign_in_error',
          { type: 'warning' },
        );

        return undefined;
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <InputField>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </InputField>
            <InputField>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </InputField>
          </Box>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && (
                <CircularProgress
                  sx={{ marginRight: 1 }}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

LoginForm.defaultProps = {
  redirectTo: undefined,
};

export const LoginPage: FunctionComponent<LoginProps> = (props: LoginProps) => (
  <Login {...props} /> // eslint-disable-line react/jsx-props-no-spreading
);

LoginPage.defaultProps = {
  children: <LoginForm />,
};

export default LoginForm;
