/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';

import {
  ArrayField,
  ArrayInput,
  Datagrid,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import { ArrayCountField } from '../../fields/ArrayCountField/ArrayCountField';

export const AreasFormList: FunctionComponent = (props) => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
    <ArrayCountField source="stations" />
  </Datagrid>
);

export const AreasFormShow: FunctionComponent = (props) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
    <ArrayField source="stations">
      <Datagrid>
        <ReferenceField reference="station" source="id">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
);

const AreaInputElements = () => (
  <>
    <TextInput source="name" />
    <TextInput source="description" multiline />
    <ArrayInput source="stations">
      <SimpleFormIterator>
        <ReferenceInput reference="station" source="id">
          <SelectInput optionText="name" source="id" />
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

export const AreasFormCreate: FunctionComponent = (props) => (
  <SimpleForm {...props}>
    <TextInput source="id" />
    <AreaInputElements />
  </SimpleForm>
);

export const AreasFormEdit: FunctionComponent = (props) => (
  <SimpleForm {...props}>
    <TextField source="id" />
    <AreaInputElements />
  </SimpleForm>
);
