import { AreasList } from './list';
import { AreasEdit } from './edit';
import { AreasCreate } from './create';
import { AreasShow } from './show';
import { icon } from './icon';

export const Areas = {
  name: 'area',
  list: AreasList,
  edit: AreasEdit,
  create: AreasCreate,
  show: AreasShow,
  icon,
};
