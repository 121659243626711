/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';

import {
  Datagrid,
  SimpleForm,
  SimpleShowLayout,
  RichTextField,
  SimpleFormProps,
  TextField,
  TextInput,
} from 'react-admin';

import { RichTextInput } from 'ra-input-rich-text';

export const ListLayout: FunctionComponent = (props) => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" sortable={false} />
    <TextField source="station" sortable={false} />
    <TextField source="title" sortable={false} />
  </Datagrid>
);

export const ShowLayout: FunctionComponent = (props) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" />
    <TextField source="station" />
    <TextField source="title" />
    <RichTextField source="ingress" />
    <RichTextField source="text" />
  </SimpleShowLayout>
);

type EventForm = FunctionComponent<Partial<SimpleFormProps>>;

export const CreateForm: EventForm = (props: Partial<SimpleFormProps>) => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="title" required />
    <TextInput source="station" required />
    <TextInput source="series" />
    <RichTextInput source="ingress" />
    <RichTextInput source="text" />
  </SimpleForm>
);

export const EditForm: EventForm = (props: Partial<SimpleFormProps>) => (
  <SimpleForm {...props} redirect="list">
    <TextField source="id" />
    <TextInput source="title" />
    <TextInput source="station" />
    <TextInput source="series" />
    <RichTextInput source="ingress" />
    <RichTextInput source="text" />
  </SimpleForm>
);
