/* eslint-disable react/jsx-props-no-spreading */

import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Amplify } from 'aws-amplify';

import { Route } from 'react-router';

import {
  APIDataProvider,
  useAuthProvider,
  i18nProvider,
} from '../../providers';
import { awsconfig, darkTheme as theme } from '../../config';
import { MyLayout } from '../MyLayout';

import { ResetPassword } from '../../ui/ResetPassword';

import { resources } from '../../resources';

import { LoginPage } from '../../ui/Login';

import type { FunctionComponent } from 'react'; // eslint-disable-line import/order

const dataProvider = APIDataProvider();

export const App: FunctionComponent = () => {
  const authProvider = useAuthProvider();

  return (
    <Admin
      theme={theme}
      layout={MyLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
    >
      <CustomRoutes noLayout>
        <Route path="/resetpassword" element={<ResetPassword />} />
      </CustomRoutes>
      {resources.map((item) => (
        <Resource {...item} />
      ))}
    </Admin>
  );
};

Amplify.configure(awsconfig);

export default App;
