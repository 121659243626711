import { FunctionComponent } from 'react';

import { Edit } from 'react-admin';
import { CreateEdit } from './base';

export const ConfigEdit: FunctionComponent = () => (
  <Edit>
    <CreateEdit />
  </Edit>
);
