import { FunctionComponent } from 'react';

import { Edit } from 'react-admin';
import { StationsFormEdit } from './base';

export const StationsEdit: FunctionComponent = () => (
  <Edit>
    <StationsFormEdit />
  </Edit>
);
