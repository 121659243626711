import { FunctionComponent } from 'react';

import { Create as RACreate } from 'react-admin';
import { CreateForm } from './base';

export const Create: FunctionComponent = () => (
  <RACreate>
    <CreateForm />
  </RACreate>
);
