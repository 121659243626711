import { FunctionComponent } from 'react';

import { List } from 'react-admin';
import { AreasFormList } from './base';

export const AreasList: FunctionComponent = () => (
  <List>
    <AreasFormList />
  </List>
);
