import { FunctionComponent } from 'react';

import { Chip } from '@mui/material';

type Identifier = string | number;
type Item = string;

interface Record {
  id: Identifier;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface Props {
  record?: Record;
  source?: string;
}

const ArrayChipField: FunctionComponent<Props> = ({ record, source = '' }) => {
  if (typeof record === 'undefined') {
    return null;
  }

  return (
    <>
      {record[source].map((item: Item) => (
        <Chip label={item} key={item} />
      ))}
    </>
  );
};

ArrayChipField.defaultProps = {
  record: undefined,
  source: '',
};

export { ArrayChipField };
