import { FunctionComponent } from 'react';

import { Show } from 'react-admin';

import { ProviderFormShow } from './base';

export const ProviderShow: FunctionComponent = () => (
  <Show>
    <ProviderFormShow />
  </Show>
);
