import HearingIcon from '@mui/icons-material/Hearing';

import { ConfigList } from './list';
import { ConfigEdit } from './edit';
import { ConfigCreate } from './create';
import { ConfigShow } from './show';

export const Config = {
  name: 'config',
  list: ConfigList,
  edit: ConfigEdit,
  create: ConfigCreate,
  show: ConfigShow,
  icon: HearingIcon,
};
