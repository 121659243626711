import { FunctionComponent } from 'react';

import { Create } from 'react-admin';
import { AreasFormCreate } from './base';

export const AreasCreate: FunctionComponent = () => (
  <Create>
    <AreasFormCreate />
  </Create>
);
