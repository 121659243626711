import { FunctionComponent, ReactNode } from 'react';

interface ControlHolderProps {
  label: string;
  children: ReactNode;
}

const fieldsetStyle = { display: 'flex', columnGap: '10px' };

export const ControlHolder: FunctionComponent<ControlHolderProps> = ({
  label,
  children,
}) => (
  <fieldset style={fieldsetStyle}>
    {label && <legend>{label}</legend>}
    {children}
  </fieldset>
);
