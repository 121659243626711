import { FunctionComponent } from 'react';

import { Create } from 'react-admin';
import { StationsFormCreate } from './base';

export const StationsCreate: FunctionComponent = () => (
  <Create>
    <StationsFormCreate />
  </Create>
);
