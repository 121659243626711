import { FunctionComponent } from 'react';

import { List as RAList } from 'react-admin';
import { ListLayout } from './base';

export const List: FunctionComponent = () => (
  <RAList>
    <ListLayout />
  </RAList>
);
