import { stringify } from 'query-string';
import { DataProvider } from 'ra-core';

import { API } from 'aws-amplify';

export const APIDataProvider = (
  updateApiName = 'ApiGatewayRestApi',
  apiName = 'CloudFrontRestApi',
  countHeader = 'Content-Range',
): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };

    const addRangeHeader = countHeader === 'Content-Range';

    const iheaders = new Headers({
      range: `bytes=${rangeStart}-${rangeEnd}`,
    });

    const url = `/${resource}?${stringify(query)}`;
    const options = addRangeHeader
      ? {
          mode: 'cors',
          headers: iheaders,
          response: true,
        }
      : {
          response: true,
        };

    console.info(JSON.stringify(iheaders)); // eslint-disable-line no-console

    return API.get(apiName, url, options)
      .then(({ headers, data }) => ({
        headers: new Headers(headers),
        data,
      }))
      .then(({ headers, data: { entities: data } }) => {
        const [, endRange = '0'] =
          countHeader === 'Content-Range'
            ? (headers.get('content-range') || '').split('/')
            : ['', headers.get(countHeader.toLowerCase())];

        return {
          data,
          total: parseInt(endRange || '0', 10) || data.length,
        };
      });
  },

  getOne: (resource, params) =>
    API.get(apiName, `/${resource}/${params.id}`, { response: true }).then(
      ({ data: { entity: data } }) => ({
        data,
      }),
    ),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    const url = `/${resource}?${stringify(query)}`;

    console.info(url); // eslint-disable-line no-console

    return API.get(apiName, url, {})
      .then((result) => {
        console.info(JSON.stringify('Got back data', null, 2)); // eslint-disable-line no-console
        console.info(JSON.stringify(result, null, 2)); // eslint-disable-line no-console

        return result;
      })
      .then(({ entities: data }) => ({
        data,
      }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    console.info('content: ', countHeader); // eslint-disable-line no-console

    const url = `/${resource}?${stringify(query)}`;
    const options =
      countHeader === 'Content-Range'
        ? {
            headers: new Headers({
              Range: `bytes=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    console.info(JSON.stringify(options)); // eslint-disable-line no-console

    return API.get(apiName, url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response.
            The simple REST data provider expects responses for lists of
            resources to contain this header with the total number of results
            to build the pagination. If you are using CORS, did you declare
            ${countHeader} in the Access-Control-Expose-Headers header?`,
        );
      }
      return {
        data: json,
        total:
          countHeader === 'Content-Range'
            ? parseInt(headers.get('content-range').split('/').pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase()), 10),
      };
    });
  },

  update: (resource, params) =>
    API.put(updateApiName, `/${resource}`, {
      method: 'PUT',
      body: params.data,
      response: true,
    })
      .then(({ data }) => data || {})
      .then(({ enitity: data = params.data }) => ({ data })),

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        API.put(updateApiName, `/${resource}/${id}`, {
          method: 'PUT',
          body: params.data,
          response: true,
        }),
      ),
    ).then((responses) => ({
      data: responses.map(
        ({
          data: {
            entity: { id },
          },
        }) => id,
      ),
    })),

  create: (resource, params) => {
    console.info('What is this'); // eslint-disable-line no-console

    console.info(resource, JSON.stringify(params, null, 2)); // eslint-disable-line no-console

    return API.post(updateApiName, `/${resource}/`, {
      body: params.data,
      response: true,
    }).then(({ data: { entity: data } }) => ({
      data,
    }));
  },

  delete: (resource, params) =>
    API.del(updateApiName, `/${resource}/${params.id}`, {
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      response: true,
    }).then(({ data: { entity: data } }) => ({ data })),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        API.del(updateApiName, `/${resource}/${id}`, {
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
          response: true,
        }),
      ),
    ).then((responses) => ({
      data: responses.map(
        ({
          data: {
            entity: { id },
          },
        }) => id,
      ),
    })),
});

export default APIDataProvider;
