import { ProviderList } from './list';
import { ProviderEdit } from './edit';
import { ProviderCreate } from './create';
import { ProviderShow } from './show';
import { icon } from './icon';

export const Provider = {
  name: 'provider',
  list: ProviderList,
  edit: ProviderEdit,
  create: ProviderCreate,
  show: ProviderShow,
  icon,
};
