import { FunctionComponent } from 'react';

import { Edit } from 'react-admin';
import { SeriesFormEdit } from './base';

export const SeriesEdit: FunctionComponent = () => (
  <Edit>
    <SeriesFormEdit />
  </Edit>
);
