/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';

import {
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SimpleShowLayout,
  SelectInput,
  SimpleFormProps,
  TextInput,
  TextField,
} from 'react-admin';

import { ConfigArray } from './config';
import { ConverterArray } from './converter';

// import type { Record as RARecord, Identifier } from 'react-admin';

import { timezones } from '../../config';

/*
interface MaxMin {
  min: number;
  max: number;
}

interface Series extends RARecord {
  id: Identifier;
  type: string;
  from: string;
  timezone: string;
  in: MaxMin;
  out: MaxMin;
  factor: number;
  offset: number;
}
*/

export interface Calculated {
  factor: number;
  offset: number;
}

export const SeriesFormList: FunctionComponent = (props) => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" sortable={false} />
    <ReferenceField reference="provider" source="provider">
      <TextField source="name" />
    </ReferenceField>
    <TextField source="name" sortable={false} />
    <TextField source="ref" sortable={false} />
    <ChipField source="type" sortable={false} />
    <DateField source="from" sortable={false} />
    <DateField source="from" sortable={false} />
    <DateField source="to" sortable={false} />
    <BooleanField source="active" />
    <ReferenceField reference="series" source="parent">
      <TextField source="name" />
    </ReferenceField>
    <TextField source="timezone" />
    <BooleanField source="demo" />
  </Datagrid>
);

export const SeriesFormShow: FunctionComponent = (props) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="ref" />
    <TextField source="type" />
    <DateField source="from" />
    <DateField source="to" />
    <ReferenceField reference="series" source="parent">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField reference="provider" source="provider">
      <TextField source="name" />
    </ReferenceField>
    <BooleanField source="active" />
    <TextField source="timezone" />
  </SimpleShowLayout>
);

type SeriesForm = FunctionComponent<Partial<SimpleFormProps>>;
export const SeriesFormCreate: SeriesForm = (
  props: Partial<SimpleFormProps>,
) => (
  <SimpleForm
    {...props}
    spacing={0.5}
    redirect="list"
    /* toolbar={<UserCreateToolbar calculated={calculated} />} */
  >
    <TextInput source="id" />
    <ReferenceInput reference="provider" source="provider">
      <SelectInput optionText="id" source="id" filter="" />
    </ReferenceInput>
    <TextInput source="name" />
    <TextInput source="ref" />

    <SelectInput
      source="type"
      choices={[
        { id: 'distance', name: 'Distance' },
        { id: 'temperature', name: 'Temperature' },
        { id: 'ph', name: 'PH' },
      ]}
    />

    <BooleanInput source="active" defaultValue="true" />

    <DateInput source="from" />
    <DateInput source="to" />

    <ReferenceInput reference="series" source="parent">
      <SelectInput optionText="id" source="id" filter="" />
    </ReferenceInput>

    <SelectInput source="timezone" choices={timezones} />

    <SelectInput
      source="cache"
      choices={[
        { id: 'asml', name: 'amsl' },
        { id: 'temperature', name: 'temperature' },
        { id: 'ph', name: 'ph' },
        { id: 'distance', name: 'distance' },
      ]}
    />

    <BooleanInput source="demo" />

    <TextInput source="profile" />

    <ConfigArray />
    <ConverterArray />
  </SimpleForm>
);

export const SeriesFormEdit = SeriesFormCreate;
