import { FunctionComponent } from 'react';

import { List } from 'react-admin';
import { ProviderFormList } from './base';

export const ProviderList: FunctionComponent = () => (
  <List>
    <ProviderFormList />
  </List>
);
