import { FunctionComponent } from 'react';

import { Create } from 'react-admin';
import { ProviderFormCreate } from './base';

export const ProviderCreate: FunctionComponent = () => (
  <Create>
    <ProviderFormCreate />
  </Create>
);
