import { FunctionComponent } from 'react';

import { Create } from 'react-admin';
import { CreateEdit } from './base';

export const ConfigCreate: FunctionComponent = () => (
  <Create>
    <CreateEdit />
  </Create>
);
