import { SeriesList } from './list';
import { SeriesEdit } from './edit';
import { SeriesCreate } from './create';
import { SeriesShow } from './show';
import { icon } from './icon';

export const Series = {
  name: 'series',
  list: SeriesList,
  edit: SeriesEdit,
  create: SeriesCreate,
  show: SeriesShow,
  icon,
};
