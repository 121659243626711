import { FunctionComponent } from 'react';

import { List } from 'react-admin';
import { StationsFormList } from './base';

export const StationsList: FunctionComponent = () => (
  <List>
    <StationsFormList />
  </List>
);
