import { FunctionComponent } from 'react';

import { Show } from 'react-admin';
import { StationsFormShow } from './base';

export const StationsShow: FunctionComponent = () => (
  <Show>
    <StationsFormShow />
  </Show>
);
