import { FunctionComponent } from 'react';

import { Badge } from '@mui/material';

import LocationOnIcon from '@mui/icons-material/PushPin';
import TimelineIcon from '@mui/icons-material/Timeline';

type Identifier = string | number;

interface Record {
  id: Identifier;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface Props {
  record?: Record;
  source?: string;
  icon?: string;
}

const ArrayCountField: FunctionComponent<Props> = ({
  record,
  source = '',
  icon = 'area',
}) => {
  if (typeof record === 'undefined' || typeof record[source] === 'undefined') {
    return null;
  }

  const count = (record[source] || []).length;

  return (
    <Badge badgeContent={count}>
      {icon === 'area' ? <LocationOnIcon /> : <TimelineIcon />}
    </Badge>
  );
};

ArrayCountField.defaultProps = {
  record: undefined,
  source: '',
  icon: 'area',
};

export { ArrayCountField };
