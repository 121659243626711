import { DateTime } from 'luxon';
import tzdata from 'tzdata';

const { zones } = tzdata;

const timezones = Object.entries(zones)
  .filter(([zoneName, v]) => Array.isArray(v))
  .map(([zoneName, v]) => zoneName)
  .filter((tz) => DateTime.local().setZone(tz).isValid)
  .sort((tzA, tzB) => tzA.localeCompare(tzB))
  .map((tz) => ({ id: tz, name: tz }));

export { timezones };
