import { mergeTranslations, TranslationMessages } from 'ra-core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import intotoEnglishMessages from './messages';

const messages: { [key: string]: TranslationMessages } = {
  en: mergeTranslations(englishMessages, intotoEnglishMessages),
};

export const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);

export default i18nProvider;
