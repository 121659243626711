/* eslint-disable react/jsx-props-no-spreading */

import {
  ArrayInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  SimpleFormIterator,
} from 'react-admin';

import { ControlHolder } from './controlholder';

export const ConfigArray = () => (
  <ArrayInput source="config">
    <SimpleFormIterator className="config-form">
      <FormDataConsumer>
        {({ getSource = (name) => name, formData }) => (
          <ControlHolder label="Period">
            <DateInput
              source={getSource('from')}
              label="From"
              defaultValue={formData.from}
            />
            <DateInput
              source={getSource('to')}
              label="To"
              defaultValue={formData.to}
            />
          </ControlHolder>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name }) => (
          <ControlHolder label="Elavation/Altitude">
            <NumberInput source={getSource('Offset')} label="Offset" />
          </ControlHolder>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name }) => (
          <ControlHolder label="In boundary">
            <NumberInput source={getSource('in.min')} label="Min" />
            <NumberInput source={getSource('in.max')} label="Max" />
          </ControlHolder>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name }) => (
          <ControlHolder label="Out boundary">
            <NumberInput source={getSource('out.min')} label="Min" />
            <NumberInput source={getSource('out.max')} label="Max" />
          </ControlHolder>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ getSource = (name) => name, scopedFormData }) => {
          if (typeof scopedFormData !== 'undefined') {
            const { in: _in = { min: 0, max: 0 }, out = { min: 0, max: 0 } } =
              scopedFormData;

            /* eslint-disable */
            scopedFormData.factor = (out.min - out.max) / (_in.min - _in.max);
            scopedFormData.variable = out.min - scopedFormData.factor * _in.min;
            /* eslint-enable */
          }

          return (
            <ControlHolder label="Calculation">
              <NumberInput
                disabled
                source={getSource('variable')}
                label="Variable"
                defaultValue={scopedFormData.variable}
              />
              <NumberInput
                disabled
                source={getSource('factor')}
                label="Factor"
                defaultValue="0"
              />
            </ControlHolder>
          );
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

export default ConfigArray;
