import { FunctionComponent } from 'react';

import { Show as RAShow } from 'react-admin';

import { ShowLayout } from './base';

export const Show: FunctionComponent = () => (
  <RAShow>
    <ShowLayout />
  </RAShow>
);
