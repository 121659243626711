/* eslint-disable camelcase */

import { Auth } from 'aws-amplify';

// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2021-03-23T14:31:41.774Z

interface IAWSAmplifyFederatedConfiguration {
  google_client_id?: string;
  facebook_app_id?: string;
  amazon_client_id?: string;
}

interface IAWSAuthorization {
  Authorization: string;
}

interface IAWSAmplifyCloudLogicConfiguration {
  [index: number]: {
    endpoint: string;
    name: string;
    region: string;
    custom_header?: () => Promise<IAWSAuthorization>;
  };
}

interface IAWSCognitoPasswordProtectionSettings {
  passwordPolicyMinLength: number;
  passwordPolicyCharacters?: string[];
}

interface IAWSAmplifyConfiguration {
  aws_appsync_authenticationType?: string;
  aws_appsync_graphqlEndpoint?: string;
  aws_appsync_region?: string;
  aws_cognito_identity_pool_id?: string;
  aws_cognito_region?: string;
  aws_cloud_logic_custom?: IAWSAmplifyCloudLogicConfiguration;
  aws_project_region: string;
  aws_user_files_s3_bucket?: string;
  aws_user_files_s3_bucket_region?: string;
  aws_user_pools_id?: string;
  aws_user_pools_web_client_id?: string;
  aws_user_pools_web_client_secret?: string;
  aws_cognito_username_attributes?: string[];
  aws_cognito_social_providers?: string[];
  aws_cognito_signup_attributes?: string[];
  aws_cognito_mfa_types?: string[];
  aws_cognito_mfa_configuration?: string;
  federated?: IAWSAmplifyFederatedConfiguration;
  aws_cognito_password_protection_settings?: IAWSCognitoPasswordProtectionSettings;
  aws_cognito_verification_mechanisms?: string[];
  oauth: unknown;
}

const awsmobile: IAWSAmplifyConfiguration = {
  aws_cloud_logic_custom: [
    {
      endpoint: '/api',
      name: 'CloudFrontRestApi',
      region: 'eu-west-1',
    },
    {
      endpoint: '/api',
      name: 'ApiGatewayRestApi',
      region: 'eu-west-1',
      custom_header: () =>
        Auth.currentSession()
          .then((session) => session.getIdToken())
          .then((idToken) => idToken.getJwtToken())
          .then((token) => ({
            Authorization: `Bearer ${token}`,
          })),
    },
  ],
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL ||
    'eu-west-1:a958ad95-2074-4c3f-85c2-6a66573b4119',
  aws_cognito_region: 'eu-west-1',
  aws_project_region: 'eu-west-1',
  aws_user_files_s3_bucket:
    process.env.REACT_APP_AWS_COGNITO_USER_FILES ||
    'intoto-admin-api-dev-admin-metadata',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_user_pools_id:
    process.env.REACT_APP_AWS_COGNITO_USER_POOL || 'eu-west-1_BhyRCEKQa',
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT ||
    '60k5uol694s7v484ptt1or6e2c',

  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
