import { FunctionComponent } from 'react';

import { List } from 'react-admin';
import { List as FormList } from './base';

export const ConfigList: FunctionComponent = () => (
  <List>
    <FormList />
  </List>
);
