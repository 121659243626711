import { FunctionComponent } from 'react';

import { Show } from 'react-admin';
import { SeriesFormShow } from './base';

export const SeriesShow: FunctionComponent = () => (
  <Show>
    <SeriesFormShow />
  </Show>
);
